<script setup lang="ts">
    /** Imports */
    /** vue, o365 */
    import { computed, ref, onMounted } from 'vue';
    import { getDataObjectById, getOrCreateDataObject } from 'o365-dataobject';
    import confirm from "o365.controls.confirm.ts";
    import { alert } from "o365.controls.alert.ts";
    import { ToastType } from 'o365.modules.ToastService.ts';
    import OModal from 'o365.vue.components.Modal.vue'; 
    // import MHazard from 'sja.vue.libraries.mob.HazardsMobile.vue';

     /** utils */
    import {    
                procSJAWorkflowAction, procGetMatrix, procCreateSJA, 
                procDeleteSJA, procAddResponsible, procDeleteTask, 
                procRemoveSignature, procSignSJA, modalInitialMatrix, modalFinalMatrix 
                
            } from 'sja.vue.libraries.modules.utilities.js';

    /** mobile */
    import Card from 'mobile.vue.components.Card.vue';
    import BottomSheet from "mobile.vue.components.BottomSheet.vue";
    import MContainer from "mobile.vue.components.Container.vue";


    /** Props */
    const props = defineProps({
        sjaID: Number,
        readOnly: Boolean,
        comp:[]
    });
    
    /** Computed */
    let dsTasksLength = computed(() => {
        return dsTasks.data.length;
    });
    
    let disableButton = computed (() =>{
        return dsTasks.current?.hasChanges;
    });


    /** Events */
    onMounted(() => {
        dsSJA.load();
        dsTasks.load();
    });

    /** Reference */
    const switcher = ref('Tasks');
    const TaskNo = ref();
    const Description = ref();
    const Name = ref();
    const Comment = ref();
    const showBottomSheet = ref();
    const ref_newTask = ref();
    const Task_ID = ref();

    /** Data Sources */
    const dsSJA = getOrCreateDataObject({
        id: `dsSJA_${props.sjaID}`,
        uniqueTable: 'atbl_SJA_SJA',
        maxRecords: -1,
        selectFirstRowOnLoad: true,
        viewName: 'aviw_SJA_SJA',
        allowUpdate: true,
        allowInsert: true,
        allowDelete: true,
        disableLayouts: true,
        fields: [
            { name: 'PrimKey', },
            { name: 'ID', },
            { name: 'Step_ID', },
            { name: 'Name', },
            { name: 'Description', },
            { name: 'Planned', },
            { name: 'Completed', },
            { name: 'OrgUnit_ID', },
            { name: 'IdPath', },
            { name: 'OrgUnit', },
            { name: 'Responsible_ID', },
            { name: 'Responsible', },
            { name: 'IsTemplate', },
            { name: 'Checklist_ID', },
            { name: 'CheckList_Editable', },
            { name: 'Checklist', }
        ],
        whereClause: "ID = " + props.sjaID
    });

    const dsTasks = getOrCreateDataObject({
        id: `dsTasks_${props.sjaID}`,
        uniqueTable: 'atbl_SJA_SJATasks',
        maxRecords: -1,
        viewName: 'aviw_SJA_SJATasks',
        allowUpdate: true,
        allowInsert: true,
        allowDelete: true,
        disableLayouts: true,
        fields: [
            { name: 'PrimKey', },
            { name: 'ID', },
            { name: 'SJA_ID', },
            { name: 'TaskNo', },
            { name: 'Description', },
            { name: 'Responsible_ID', },
            { name: 'Comment', }
        ],
        whereClause: `SJA_ID = ${props.sjaID}`,
        masterDataObject_ID: `dsSJA_${props.sjaID}`,
        masterDetailDefinition: [
            {
                masterField: "ID",
                detailField: "SJA_ID",
                operator: "equals"
            }
        ],
    });


    let dsActions = getOrCreateDataObject({
        id: `dsTasksHazardsActions_${props.sjaID}`,
        uniqueTable: 'atbv_SJA_SJATasksHazardsActions',
        maxRecords: -1,
        viewName: 'aviw_SJA_SJATasksHazardsActions',
        allowUpdate: true,
        allowInsert: true,
        allowDelete: true,
        disableLayouts: true,
        fields: [
            { name: 'PrimKey', },
            { name: 'ID', },
            { name: 'Name', },
            { name: 'Comment', },
            { name: 'IsVerified', },
            { name: 'SJATasksHazard_ID', },
            { name: 'SJA_ID', }
        ],
        whereClause: `SJA_ID = ${props.sjaID}`,
    });

    let dsResponsible = getOrCreateDataObject({
        id: `dsTasksHazardsActionsResp_${props.sjaID}`,
        uniqueTable: 'atbv_SJA_SJATasksHazardsActionsResponsibles',
        maxRecords: -1,
        viewName: 'aviw_SJA_SJATasksHazardsActionsResponsibles',
        allowUpdate: true,
        allowInsert: true,
        allowDelete: true,
        disableLayouts: true,
        fields: [
            { name: 'PrimKey', },
            { name: 'ID', },
            { name: 'SJATasksHazardsAction_ID', },
            { name: 'Responsible_ID', },
            { name: 'StepAction_ID', },
            { name: 'Completed', },
            { name: 'SJA_ID', },
            { name: 'Name', }
        ],
        whereClause: `SJA_ID = ${props.sjaID}`,
    });

    let dsHazards = getOrCreateDataObject({
        id: `dsTasksHazards_${Task_ID}`,
        uniqueTable: 'atbv_SJA_SJATasksHazards',
        maxRecords: -1,
        viewName: 'aviw_SJA_SJATasksHazards',
        allowUpdate: true,
        allowInsert: true,
        allowDelete: true, 
        disableLayouts: true,
        fields: [
            { name: 'PrimKey', },
            { name: 'ID', },
            { name: 'Name', },
            { name: 'Color', },
            { name: 'FinalColor', },
            { name: 'Matrix_ID', },
            { name: 'SJATask_ID', },
            { name: 'FinalMatrix_ID', },
            { name: 'ProbabilityNameAndDesc', },
            { name: 'ConsequenceNameAndDesc', },
            { name: 'FinalProbabilityNameAndDesc', },
            { name: 'FinalConsequenceNameAndDesc', },
            { name: 'ProbabilityValue', },
            { name: 'ConsequenceValue', },
            { name: 'SJA_ID', },
            { name: 'FinalProbabilityValue', },
            { name: 'FinalConsequenceValue', }
        ],
        
    });

    const dsConsequencesLkp = getOrCreateDataObject({
        id: `dsConsequencesLkp`,
        maxRecords: -1,
        viewName: 'aviw_SJA_Consequences',
        allowUpdate: true,
        allowInsert: true,
        allowDelete: true,
        disableLayouts: true,
        fields: [
            { name: 'ID', },
            { name: 'Name', },
            { name: 'Value', },
            { name: 'Description', },
            { name: 'ValueAndName', },
            { name: 'NameAndDescription', },
            { name: 'ValueAndNameAndDescription', }
        ],
    });

    const dsProbabilitiesLkp = getOrCreateDataObject({
        id: `dsProbabilitiesLkp`,
        maxRecords: -1,
        viewName: 'aviw_SJA_Probabilities',
        allowUpdate: true,
        allowInsert: true,
        allowDelete: true,
        disableLayouts: true,
        fields: [
            { name: 'ID', },
            { name: 'Name', },
            { name: 'Value', },
            { name: 'Description', },
            { name: 'ValueAndName', },
            { name: 'NameAndDescription', },
            { name: 'ValueAndNameAndDescription', }
        ],
    });

    const dsHazardsModalInitial = getOrCreateDataObject({
        id: `dsTasksHazardsModalInitial`,
        uniqueTable: 'atbv_SJA_SJATasksHazards',
        selectFirstRowOnLoad: true,
        maxRecords: -1,
        viewName: 'aviw_SJA_SJATasksHazards',
        allowUpdate: true,
        allowInsert: true,
        allowDelete: true,
        disableLayouts: true,
        fields: [
            { name: 'PrimKey', },
            { name: 'ID', },
            { name: 'Name', },
            { name: 'Color', },
            { name: 'FinalColor', },
            { name: 'Matrix_ID', },
            { name: 'SJATask_ID', },
            { name: 'SJA_ID', },
            { name: 'FinalMatrix_ID', },
            { name: 'ProbabilityNameAndDesc', },
            { name: 'ConsequenceNameAndDesc', },
            { name: 'FinalProbabilityNameAndDesc', },
            { name: 'FinalConsequenceNameAndDesc', },
            { name: 'ProbabilityValue', },
            { name: 'ConsequenceValue', },
            { name: 'FinalProbabilityValue', },
            { name: 'FinalConsequenceValue', }
        ],
    });

    const dsHazardsModalFinal = getOrCreateDataObject({
        id: `dsTasksHazardsModalFinal`,
        uniqueTable: 'atbv_SJA_SJATasksHazards',
        selectFirstRowOnLoad: true,
        maxRecords: -1,
        viewName: 'aviw_SJA_SJATasksHazards',
        allowUpdate: true,
        allowInsert: true,
        allowDelete: true,
        disableLayouts: true,
        fields: [
            { name: 'PrimKey', },
            { name: 'ID', },
            { name: 'Name', },
            { name: 'Color', },
            { name: 'FinalColor', },
            { name: 'Matrix_ID', },
            { name: 'SJATask_ID', },
            { name: 'SJA_ID', },
            { name: 'FinalMatrix_ID', },
            { name: 'ProbabilityNameAndDesc', },
            { name: 'ConsequenceNameAndDesc', },
            { name: 'FinalProbabilityNameAndDesc', },
            { name: 'FinalConsequenceNameAndDesc', },
            { name: 'ProbabilityValue', },
            { name: 'ConsequenceValue', },
            { name: 'FinalProbabilityValue', },
            { name: 'FinalConsequenceValue', },
            { name: 'ProbabilityValueID', },
            { name: 'ConsequenceValueID', },
            { name: 'FinalProbabilityValueID', },
            { name: 'FinalConsequenceValueID', }
        ],
    });


    /** Const */
    const vDS = ["dsTasks", "dsHazards", "dsActions","dsResponsible"];
    const vTaskFields = ["TaskNo", "Description"];
    const vHazardsFields = ["Name"];
    const vActionsFields = ["Name", "Comment"];
    const vResponsiblesFields = ["Name"];

    /** General Methods */
    
    function resetAndDismiss(){
        TaskNo.value = null;
        Description.value = null;
        Name.value = null;
        Comment.value = null;
    }
    
    function setIndex(e){
        if(e !== undefined && e.index !== null){
            if(switcher.value === "Tasks"){ 
                console.log("Tasks");
                dsTasks.setCurrentIndex(e.index);
            }
            if(switcher.value === "Hazards"){
                dsHazards.setCurrentIndex(e.index);
                console.log("Hazards");
            }
            if(switcher.value === "Actions"){
                dsActions.setCurrentIndex(e.index);
                console.log("Actions");
            }
            if(switcher.value === "Responsibles"){
                dsResponsible.setCurrentIndex(e.index);
                console.log("Responsibles");
            }
        }
    }

    async function saveChanges(){
        if(switcher.value === "Tasks"){ 
            await dsTasks.save();
        }
        if(switcher.value === "Hazards"){
            await dsHazards.save();
        }
        if(switcher.value === "Actions"){
            await dsActions.save();
        }
        if(switcher.value === "Responsibles"){
            await dsResponsible.save();
        }
    }
    function unSetIndex(){
        if(switcher.value === "Tasks"){ 
            dsTasks.unsetCurrentIndex();
        }
        if(switcher.value === "Hazards"){
            dsHazards.unsetCurrentIndex();
        }
        if(switcher.value === "Actions"){
            dsActions.unsetCurrentIndex();
        }
        if(switcher.value === "Responsibles"){
            dsResponsible.unsetCurrentIndex();
        }
    }

    function discard(pIndex){
        if(switcher.value === "Tasks"){ 
            dsTasks.cancelChanges(pIndex);
        }
        if(switcher.value === "Hazards"){
            dsHazards.cancelChanges(pIndex);
        }
        if(switcher.value === "Actions"){
            dsActions.cancelChanges(pIndex);
        }
        if(switcher.value === "Responsibles"){
            dsResponsible.cancelChanges(pIndex);
        }
    }

    function onModalShown(modal){
        console.log(modal);
        if(modal !== null && modal !== undefined){
            let _modal = modal.value.modal._element;
            _modal.style.zIndex = '10000';
            _modal.style.position='absolute';
        }else{
            document.getElementById('#CreateModal').style.zIndex = '10000';
            document.getElementById('#CreateModal').style.position='absolute';
        }
    }

    async function deleteRow(row, pType){ 
        if(pType === "Tasks"){
            await confirm({
                title: $t("Delete Task"),
                message: $t(`Are you sure you wish to delete ${row.Description}?`),
                btnTextOk: $t("Yes"),
                btnTextCancel: $t("No"),
            }).then(async () => {
                await procDeleteTask.execute({ PrimKey: row.PrimKey });
                dsTasks.load();
            }).catch(() => {
            });
        }
        
    } 

    /** Tasks Methods */

    function createNewRec(pSwitcher){
        if(pSwitcher === "Hazards"){
            let _Name = Name.value;
            
            if(_Name !== undefined || _Name !== null){
                dsHazards.createNew({Name: _Name, SJATask_ID: Task_ID}, false);
                dsHazards.load();
                bootstrap.Modal.getInstance(document.getElementById('#CreateModal')).hide();
                resetAndDismiss();
            }else{
                alert($t(`Please fill in all fields`), ToastType.Danger, { autohide: true, delay: 3000 });
            }
        }else if(pSwitcher === "Tasks"){
            let _TaskNo: number;
            let _Description: string;
            _TaskNo = Number(TaskNo.value);
            _Description = Description.value;
            
            if(_TaskNo !== undefined && _Description !== undefined){
                if(_TaskNo.toString() === 'NaN'){
                    alert($t(`Invalid input. Please enter a number in TaskNo`), ToastType.Danger, { autohide: true, delay: 3000 });
                }
                dsTasks.createNew({TaskNo: _TaskNo, Description: _Description}, false);
                dsTasks.load();
                bootstrap.Modal.getInstance(document.getElementById('#CreateModal')).hide();
                resetAndDismiss();
            }else{
                alert($t(`Please fill in all fields`), ToastType.Danger, { autohide: true, delay: 3000 });
            }
        }
        
    }

    window.dsTasks = dsTasks;
    window.dsHazards = dsHazards;
    
    window.SwitchComponentObj = {
        comp: switcher,
        TaskID: Task_ID,
        sjaid: props.sjaID
    };
    
    /** Hazard Methods */

    function loadTaskHazards(pTaskID){
        if(pTaskID !== null || pTaskID !== undefined){
            dsHazards.recordSource.whereClause = 'SJATask_ID =' + String(Task_ID.value) ;
            dsHazards.load();
            dsProbabilitiesLkp.load();
            dsConsequencesLkp.load();
            dsHazardsModalFinal.load();
            dsHazardsModalInitial.load();
        }
    }

    function openModal(hazard, modaltype) {
        const dsHazardsModalInitial = getDataObjectById(`dsTasksHazardsModalInitial`);
        const dsHazardsModalFinal = getDataObjectById(`dsTasksHazardsModalFinal`);
        dsHazardsModalInitial.recordSource.whereClause = `ID = ${hazard.ID}`
        dsHazardsModalFinal.recordSource.whereClause = `ID = ${hazard.ID}`
        if (modaltype === "Initial") {
            dsHazardsModalInitial.load().then(() => {
                modalInitialMatrix.value.show().then(()=> {
                    onModalShown(modalInitialMatrix);
                });
            });
        }
        if (modaltype === "Final") {
            dsHazardsModalFinal.load().then(() => {
                modalFinalMatrix.value.show().then(()=>{
                    onModalShown(modalFinalMatrix);
                });
            });
        }
        
    }

    /** Actions Methods */

    

</script>

<style scoped>

    .taskEditBtn{
        padding-top: 2px;
        padding-bottom: 2px;
        padding-left: 4px;
        padding-right: 4px;
    }
   .btn-close{
        box-shadow: none;
        font-size: 13px;
   }

    .fade-enter-active,
    .fade-leave-active {
        transition: opacity 0.5s ease;
    }

    .fade-enter-from,
    .fade-leave-to {
        opacity: 0;
    }

    .btn-new{
        border-radius: 2.0rem;
        overflow: hidden;
        background-color: #4682B4;
        color: white;
    }
    
</style>

<template>

    <button class="btn btn-outline-primary w-100 dropdown-toggle" @click="showBottomSheet = true">
        {{ $t('Tasks') + ': ' + dsTasksLength }}
    </button>
    
    <BottomSheet v-model="showBottomSheet" min-height="calc(100vh - 4rem)">

        <template #title>
            <div class="d-flex justify-content-center align-items-center">
                <transition name="fade" mode="out-in">
                    <template v-if="switcher === 'Hazards'">
                        <div style="position: absolute;">
                            {{ $t("Hazards") }}
                        </div>
                    </template>
                    <template v-else-if="switcher === 'Actions'">
                        <div style="position: absolute;">
                            {{ $t("Actions") }}
                        </div>
                    </template>
                    <template v-else>
                        <div style="position: absolute;">
                            {{ $t("Tasks") }}
                        </div>
                    </template>
                </transition>
            </div>
        </template>

        <template #action>
            <transition name="fade" mode="out-in">
                <template v-if="switcher === 'Hazards'">
                    <div class="btn" @click="switcher = 'Tasks'">
                        <i class="bi bi-arrow-left fs-4" style="color: gray"/>
                    </div>
                </template>
                <template v-else-if="switcher ==='Actions'">
                    <div class="btn" @click="switcher = 'Hazards'">
                        <i class="bi bi-arrow-left fs-4" style="color: gray"/>
                    </div>
                </template>
                <template v-else>
                    <div></div> 
                </template>
            </transition>
        </template>

        <template #body>
            <MContainer>
                <transition name="fade" mode="out-in">
                    <template v-if="switcher === 'Hazards'">
                        <MContainer class="d-flex flex-column mb-3">
                            <div class="p-3 mb-auto">
                                <div class="row g-2">
                                    <template v-for="item in dsHazards.data">
                                        <div class="d-flex" v-if="!item.isNewRecord">
                                            <div class="flex-grow-1">
                                                <MCard> 
                                                    <span @click="switcher = 'Actions'"> {{ item.Name }} </span> 
                                                    <div class="btn btn-sm float-end dropdown" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                        <i class="bi bi-three-dots-vertical"></i>
                                                    </div>
                                                    <div class="dropdown" style="font-size: 17px;">
                                                        <ul class="dropdown-menu">
                                                            <li class="d-flex">
                                                                <a class="dropdown-item flex-grow-1" data-bs-toggle="offcanvas" data-bs-target="#showEdit" aria-controls="showEdit" @click="setIndex(item)"> {{ $t('Edit') }} 
                                                                    <i class="bi bi-pencil-square float-end "></i>
                                                                </a>
                                                            </li>
                                                            <li class="d-flex">
                                                                <a class="dropdown-item flex-grow-1" @click="!props.readOnly ? openModal(item, 'Final') : ''"> {{ $t('Final risk assessment') }} 
                                                                    <i :title="$t('Edit the final risk assessment.') + '\n' 
                                                                                + $t('Probability') + ': ' + (item.FinalProbabilityNameAndDesc ? item.FinalProbabilityNameAndDesc : ' ') + '\n' 
                                                                                + $t('Consequence') + ': ' + (item.FinalConsequenceNameAndDesc ? item.FinalConsequenceNameAndDesc : ' ')" 
                                                                        type="button"
                                                                        :style="{ 'color': item.FinalColor, 'cursor': props.readOnly ? 'default' : 'pointer' }"
                                                                        class="fs-5 bi bi-square-fill float-end"></i>
                                                                </a>
                                                            </li>
                                                            <li class="d-flex">
                                                                <a class="dropdown-item flex-grow-1" @click="!props.readOnly ? openModal(item, 'Initial') : ''"> {{ $t('Initial risk assessment') }} 
                                                                    <i :title="$t('Edit the initial risk assessment.') + '\n' 
                                                                                + $t('Probability') + ': ' + (item.ProbabilityNameAndDesc ? item.ProbabilityNameAndDesc : ' ') + '\n' 
                                                                                + $t('Consequence') + ': ' + (item.ConsequenceNameAndDesc ? item.ConsequenceNameAndDesc : ' ')" 
                                                                        type="button"
                                                                        :style="{ 'color': item.Color, 'cursor': props.readOnly ? 'default' : 'pointer' }"
                                                                        class="fs-5 bi bi-square-fill float-end"></i>
                                                                </a>
                                                            </li>
                                                            <li class="d-flex">
                                                                <a class="dropdown-item flex-grow-1 text-danger"> {{ $t('Delete') }} 
                                                                    <i class="bi bi-x float-end"></i>
                                                                </a>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </MCard>    
                                            </div>
                                        </div>
                                    </template>
                                    
                                </div>
                            </div> 
                            <div class="d-flex p-3" style="margin-left: auto;">
                                <a class="btn flex-grow-1 btn-new" @click="$refs.ref_newTask.show">
                                    <i class="bi bi-plus fs-2 float-end"></i>
                                </a>      
                            </div>                   
                        </MContainer>
                    </template>
                    <template v-else-if="switcher === 'Actions'">
                        <div> Actions </div>
                    </template>
                    <template v-else-if="switcher === 'Tasks'">
                        <MContainer class="d-flex flex-column mb-3">
                            <div class="p-3 mb-auto">
                                <div class="row g-2">
                                    <template v-for="item in dsTasks.data">
                                        <div class="d-flex" v-if="!item.isNewRecord">
                                            <div class="flex-grow-1">
                                                <Card> 
                                                    <span @click="switcher = 'Hazards'; Task_ID = item.ID; loadTaskHazards(Task_ID);"> {{ item.TaskNo + '. ' + item.Description }} </span> 
                                                    <div class="btn btn-sm float-end taskEditBtn dropdown" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                        <i class="bi bi-three-dots-vertical"></i>
                                                    </div>
                                                    <div class="dropdown">
                                                        <ul class="dropdown-menu">
                                                            <li class="d-flex">
                                                                <a class="dropdown-item flex-grow-1" data-bs-toggle="offcanvas" data-bs-target="#showEdit" aria-controls="showEdit" @click="setIndex(item)"> {{ $t('Edit') }} 
                                                                    <i class="bi bi-pencil-square float-end"></i>
                                                                </a>
                                                            </li>
                                                            <li class="d-flex">
                                                                <a class="dropdown-item flex-grow-1 text-danger" @click="deleteRow(item, 'Tasks')"> {{ $t('Delete') }} 
                                                                    <i class="bi bi-x float-end"></i>
                                                                </a>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </Card>    
                                            </div>
                                        </div>
                                    </template>
                                </div>
                            </div>
                            <div class="d-flex p-3" style="margin-left: auto;">
                                <a class="btn flex-grow-1 btn-new" @click="$refs.ref_newTask.show">
                                    <i class="bi bi-plus fs-2 float-end"></i>
                                </a>      
                            </div> 
                        </MContainer>                    
                    </template>
                </transition>
                <div class="offcanvas offcanvas-end" tabindex="-1" id="showEdit" aria-labelledby="offcanvasL" @hidden="unSetIndex()">
                    <div class="offcanvas-header">
                        <h5 id="offcanvasL">{{ $t('Editing Current ') + switcher + ':' }}</h5>
                        <button type="button" class="btn-close text-reset " data-bs-dismiss="offcanvas" aria-label="Close"></button>
                    </div>
                    <div class="offcanvas-body" >
                        <div class="mb-3" v-if="switcher === 'Tasks'">
                            <label for="ITaskNo" class="form-label">{{ $t('TaskNo') }}</label>
                            <input id="ITaskNo" class="form-control" v-model="dsTasks.current.TaskNo"/>
                        </div>
                        <div class="mb-3" v-if="switcher === 'Tasks'">
                            <label for="IDescription" class="form-label">{{ $t('Description') }}</label>
                            <input id="IDescription" class="form-control" v-model="dsTasks.current.Description"/>
                        </div>
                        <div class="mb-3" v-if="switcher === 'Hazards'">
                            <label for="IName" class="form-label">{{ $t('Name') }}</label>
                            <input id="IName" class="form-control" v-model="dsHazards.current.Name"/>
                        </div>
                        <div class="mt-5">
                            <button type="button" :class="{ 'disabled': !disableButton }" class="btn btn-primary mx-1" @click="saveChanges()">                  {{ $t('Save') }}  </button>
                            <button type="button" :class="{ 'disabled': !disableButton }" class="btn btn-primary mx-1" @click="discard(dsTasks.current.index)"> {{ $t('Cancel') }} </button>
                        </div>
                    </div>
                </div>
                <OModal id ="#CreateModal" class="newtTaskModal" name="New-modal" ref="ref_newTask" @show="onModalShown(e)">
                    <div class="modal-dialog modal-lg">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title"> {{$t('Create New ') + switcher }} </h5>
                            </div>
                            <div class="modal-body">
                                <div class="mb-3" v-if="switcher === 'Tasks'">
                                    <label for="NTaskNo" class="form-label">{{ $t('TaskNo') }}</label>
                                    <input id="NTaskNo" class="form-control" v-model="TaskNo"/>
                                </div>
                                <div class="mb-3" v-if="switcher === 'Tasks'">
                                    <label for="NDescription" class="form-label">{{ $t('Description') }}</label>
                                    <input id="NDescription" class="form-control" v-model="Description"/>
                                </div>
                                <div class="mb-3" v-if="switcher === 'Hazards'">
                                    <label for="NName" class="form-label">{{ $t('Name') }}</label>
                                    <input id="NName" class="form-control" v-model="Name"/>
                                </div>
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-primary mx-1" @click="createNewRec(switcher)"> {{ $t('Create') }}  </button>
                                <button type="button" class="btn btn-primary mx-1" data-bs-dismiss="modal" @click="resetAndDismiss()"> {{ $t('Cancel') }} </button>
                            </div>
                        </div>
                    </div>
                </OModal>
            </MContainer>  
        </template>
    </BottomSheet>
</template>